import React from "react"

const TrustedByCompanies = ({ direction }) => {
  let companies = [
    {
      name: `pentius`,
      link: `https://www.pentius.com/`,
      logo: `pentius-small-logo.png`,
      isLink: false,
    },
  ]

  return (
    <section className={`trusted-by-companies ${direction} hide`}>
      <div className="container">
        <h2>Trusted By</h2>
        <div className="trusted-companies-list">
          <ul>
            {companies.map(company => {
              return company?.isLink === true ? (
                <li>
                  <a href={company.link} title={company.name} target="_blanc">
                    <img src={`/${company.logo}`} alt={company.name} />
                  </a>
                </li>
              ) : (
                <li title={company?.name}>
                  <img src={`/${company.logo}`} alt={company.name} />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default TrustedByCompanies
