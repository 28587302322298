import React, { useState, useEffect, useRef } from "react"
import Layout from "./../components/layout"
import SEO from "./../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import moment from "moment"
// import home page
import "./../styles/home-page.css"

//import icons
import IconRightArrow from "./../assets/icons/svg/arrow-pointing-to-right.svg"
import APIMonitoringIcon from "./../assets/icons/svg/API-website-uptime-monitoring.svg"
import StatusPageIcon from "./../assets/icons/svg/status-page-icon.svg"
import IncidentManagementIcon from "./../assets/icons/svg/incident-management.svg"
import IconSMS from "./../assets/icons/svg/sms.svg"
import IconEmail from "./../assets/icons/svg/gmail.svg"
import IconSlack from "./../assets/icons/svg/slack.svg"
import IconWebhook from "./../assets/icons/svg/webhooks.svg"
import IconTelegram from "./../assets/icons/svg/telegram.svg"
import IconOpsgenie from "./../assets/icons/svg/opsgenie-tiny-logo.svg"
import IconDiscord from "./../assets/icons/svg/discord.svg"
import IconPagerDuty from "./../assets/icons/svg/pagerduty-icon.svg"
import IconTwilio from "./../assets/icons/svg/integration-twilio.svg"
import IconVonage from "./../assets/icons/svg/integration-vonage.svg"

// import PatternHeroBackground from "./../assets/images/Landing-page-bro.svg"

// Components
import GetStartedBanner from "./../components/get-started-footer"
import GlobalCoverageSection from "./../components/global-coverage-section/global-coverage"
import PricingComparator from "./../components/competitor-price-comparator"
import TrustedByCompanies from "./../components/companies-trusted"

/**
 * HEADING
 */
const HeadingSection = () => {
  return (
    <section className="u-heading">
      <div className="container hero-container">
        {/* Left Side: Headings */}
        <div className="u-heading__left">
          <h1 className="u-heading__title">
            <span>Complete Uptime Monitoring and Status Pages.</span>
          </h1>
          <h2 className="u-heading__subtitle">
            <span>Simple Pricing, No Surprises.</span>
          </h2>
        </div>

        {/* Right Side: Description and CTA */}
        <div className="u-hero-block__desc">
          <div className="u-heading__description">
            Monitoring is essential for reliability. Odown provides a full suite of uptime tools and status pages — affordable, powerful, and easy to use.
          </div>
          <div className="u-heading__button">
            <OutboundLink
              className="btn-primary"
              eventCategory="Launch the Odown App"
              eventAction="Click"
              eventLabel="Goto signup"
              href="https://app.odown.io/signup"
            >
              Try Free for Two Weeks <IconRightArrow />
            </OutboundLink>
            <a
              href="https://status.odown.io"
              className="btn-primary dark"
              target="_blanc"
            >
              Status Page Demo{" "}
            </a>
          </div>
          <div className="u-subtitle__list">
          <ul>
            <li>No credit card required</li>
          </ul>
          <hr className="divider" />
          </div>
        </div>
      </div>
    </section>
  );
};

const ProgressBar = props => {
  const { fulldelay } = props
  const [completed, setCompleted] = useState(0)
  const ref = useRef(0)
  const mountedRef = useRef(true)

  // const containerStyles = {
  // 	height: 20,
  // 	width: '100%',
  // 	backgroundColor: "#e0e0de",
  // 	borderRadius: 50,
  // 	margin: 50
  // }

  // const fillerStyles = {
  // 	height: '100%',
  // 	width: `${completed}%`,
  // 	backgroundColor: bgcolor,
  // 	borderRadius: 'inherit',
  // 	textAlign: 'right'
  // }

  // const labelStyles = {
  // 	padding: 5,
  // 	color: 'white',
  // 	fontWeight: 'bold'
  // }

  const updateProgressStatus = () => {
    let t = setInterval(() => {
      ref.current += 10
      const completedValue = (ref.current * 100) / fulldelay

      setCompleted(completedValue)
      if (completedValue >= 100) clearInterval(t)
    }, 10)
  }

  useEffect(() => {
    updateProgressStatus()
    return () => {
      mountedRef.current = false
    }
  }, [])

  return (
    <div className="pb-wrapper">
      <div className="pb-filler" style={{ width: `${completed}%` }}>
        <span className="pb-label">{`${completed}%`}</span>
      </div>
    </div>
  )
}

/**
 * HERO
 */
const HeroSection = ({ data }) => {
  const [selectedTab, setSelectedTab] = useState("monitoring")

  const selectedTabRef = useRef("monitoring")

  const handleClick__selectTab = value => {
    setSelectedTab(value)
  }

  const tabSwitcher = () => {
    setTimeout(() => {
      selectedTabRef.current =
        selectedTabRef.current === "monitoring"
          ? "statuspage"
          : selectedTabRef.current === "statuspage"
          ? "incident-management"
          : "monitoring"

      handleClick__selectTab(selectedTabRef.current)
      tabSwitcher()
    }, 5000)
  }

  useEffect(() => {
    tabSwitcher()
  }, [])

  return (
    <section className="u-hero">
      <div className="hero-container">
        <div className="u-hero-tabs">
          <ul>
            <li
              className={selectedTab === "monitoring" ? "active" : ""}
              onClick={e => handleClick__selectTab("monitoring")}
            >
              <div className="u-hero-block">
                <div className="u-hero-block__title">
                <div className="icon-text-container">
                <APIMonitoringIcon /> API/Website Uptime Monitoring
                </div>
                </div>
                <div className="u-hero-block__desc">
                  Receive instant alerts when downtime occurs and collect
                  performance metrics.
                </div>
                {selectedTab === "monitoring" && (
                  <ProgressBar bgcolor={"#6a1b9a"} fulldelay={5000} />
                )}
              </div>
            </li>
            <li
              className={selectedTab === "statuspage" ? "active" : ""}
              onClick={e => handleClick__selectTab("statuspage")}
            >
              <div className="u-hero-block">
                <div className="u-hero-block__title">
                <div className="icon-text-container">
                <StatusPageIcon />
                Public Status Page
                </div>
                </div>
                <div className="u-hero-block__desc">
                  Communicate your site's performance to your users using
                  wonderfully simple status pages.
                </div>
                {selectedTab === "statuspage" && (
                  <ProgressBar bgcolor={"#6a1b9a"} fulldelay={5000} />
                )}
              </div>
            </li>
            <li
              className={selectedTab === "incident-management" ? "active" : ""}
              onClick={e => handleClick__selectTab("incident-management")}
            >
              <div className="u-hero-block">
                <div className="u-hero-block__title">
                <div className="icon-text-container">
                <IncidentManagementIcon />
                Incident Management</div>
                </div>
                <div className="u-hero-block__desc">
                  Resolve your issues and keep your customers informed and
                  happier via the status page
                </div>
                {selectedTab === "incident-management" && (
                  <ProgressBar bgcolor={"#6a1b9a"} fulldelay={5000} />
                )}
              </div>
            </li>
          </ul>
        </div>
        <div className="u-hero-content">
          <ul>
            <li className={selectedTab === "monitoring" ? "active" : ""}>
              <div className="u-hero-block-content">
                <div className="u-hero-content__media">
                  <Img
                    fadeIn={false}
                    loading="eager"
                    fluid={data.HeroImageMonitoring.childImageSharp.fluid}
                    imgStyle={{ objectPosition: "top center" }}
                    title="Website Monitoring"
                    alt="Website Monitoring"
                  />
                </div>
              </div>
            </li>
            <li className={selectedTab === "statuspage" ? "active" : ""}>
              <div className="u-hero-block-content">
                <div className="u-hero-content__media">
                  <Img
                    fadeIn={false}
                    loading="eager"
                    fluid={data.HeroImageStatusPage.childImageSharp.fluid}
                    alt="Status Page"
                  />
                </div>
              </div>
            </li>
            <li
              className={selectedTab === "incident-management" ? "active" : ""}
            >
              <div className="u-hero-block-content">
                <div className="u-hero-content__media">
                  <Img
                    fadeIn={false}
                    loading="eager"
                    fluid={
                      data.HeroImageIncidentManagement.childImageSharp.fluid
                    }
                    alt="Incident Management"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

const ApiMonitoringMainFeatureSection = ({ data }) => {
  return (
    <section className="u-main-feature left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <h2 className="u-main-feature_tc-header">
              API & website monitoring
            </h2>
            <h2 className="u-main-feature_tc-title">
              Website uptime monitoring that keeps you ahead
            </h2>
            <p className="u-main-feature_tc-desc">
              Measure the uptime performance of your APIs and get detailed
              response times from 17 available regions as well as HTTP errors in
              a simple interface.
            </p>
            <Link to="/product/website-monitoring" className="cta">
              See Odown Website Monitoring
            </Link>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.MainFeatureApiMonitoring.childImageSharp.fluid}
                alt="Website monitoring"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const StatusPagesMainFeatureSection = ({ data }) => {
  return (
    <section className="u-main-feature right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <h2 className="u-main-feature_tc-header">Public status pages</h2>
            <h3 className="u-main-feature_tc-title">
              Transparency inspires loyalty
            </h3>
            {/* <p className="u-main-feature_tc-desc" >The status page is the best way to share and display your websites' uptime performance in real-time with your customers. With consistent testing, our status page widget gives you a quick and transparent window into the true health of your website. Setup takes less than a minute.</p> */}
            <p className="u-main-feature_tc-desc">
              The status page is the best way to share and display your
              websites' uptime performance in real-time with your customers.
              Setup takes less than a minute.
            </p>
            {/* <p className="u-main-feature_tc-desc small" >Since our status page is updated in real-time, your customers can check the status of their connection without contacting you. Their feedback helps you identify and resolve problems quickly.</p> */}
            <Link to="/product/status-page/" className="cta">
              See Odown Status Page
            </Link>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.MainFeatureStatusPage.childImageSharp.fluid}
                alt="Status pages"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const IncidentManagementMainFeatureSection = ({ data }) => {
  return (
    <section className="u-main-feature left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <h2 className="u-main-feature_tc-header">Incident Management</h2>
            <h3 className="u-main-feature_tc-title">
              Efficient incident management
            </h3>
            <p className="u-main-feature_tc-desc">
              Spend less time managing incidents and more time resolving them so
              you don't lose valuable customers.
            </p>
            <Link to="/product/incident-management/" className="cta">
              See Odown Incident Management
            </Link>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.MainFeatureIncidentManagement.childImageSharp.fluid}
                alt="Incident management"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const SSLMonitoringMainFeatureSection = ({ data }) => {
  return (
    <section className="u-main-feature right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <h2 className="u-main-feature_tc-header">SSL monitoring</h2>
            <h3 className="u-main-feature_tc-title">
              Renew SSL before the expiration
            </h3>
            <p className="u-main-feature_tc-desc">
              Don't lose visitors because of an expired SSL certificate. Get
              notified with a custom days before expiration, so you have time to
              renew.
            </p>
            <Link to="/product/ssl-monitoring/" className="cta">
              See Odown SSL Monitoring
            </Link>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.MainFeatureSSLChecking.childImageSharp.fluid}
                alt="Status pages"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/**
 * INTEGRATION
 */
const IntegrationSection = () => {
  const alertChannels = [
    {
      name: "SMS",
      class: "integration-sms",
      component: IconSMS,
    },
    {
      name: "Email",
      class: "integration-email",
      component: IconEmail,
    },
    {
      name: "Slack",
      class: "integration-slack",
      component: IconSlack,
    },
    {
      name: "Webhooks",
      class: "integration-webhooks",
      component: IconWebhook,
    },
    {
      name: "Telegram",
      class: "integration-telegram",
      component: IconTelegram,
      isNew: moment() < moment("2021-08-24").add(30, "days"),
    },
    {
      name: "Opsgenie",
      class: "integration-opsgenie",
      component: IconOpsgenie,
      isNew: moment() < moment("2021-08-26").add(30, "days"),
    },
    {
      name: "Discord",
      class: "integration-discord",
      component: IconDiscord,
      isNew: moment() < moment("2021-08-27").add(30, "days"),
    },
    {
      name: "PagerDuty",
      class: "integration-pagerduty",
      component: IconPagerDuty,
      isNew: moment() < moment("2021-09-04").add(30, "days"),
    },
    {
      name: "Twilio",
      class: "integration-twilio",
      component: IconTwilio,
      isNew: moment() < moment("2021-12-04").add(30, "days"),
    },
    {
      name: "Vonage(Nexmo)",
      class: "integration-vonage-nexmo",
      component: IconVonage,
      isNew: moment() < moment("2021-12-04").add(30, "days"),
    },
  ]

  return (
    <section className="u-integrations">
      <div className="container">
        <h2 className="u-integrations__title">Get alerted anywhere you are</h2>
        <div className="u-integrations__description">
          Get notified instantly with a generous helping of SMS messages and
          unlimited email, Slack, Telegram, Discord, Pagerduty, Opsgenie, and
          webhook notifications.
        </div>
        <div className="u-integrations__tools">
          <ul>
            {alertChannels.map((item, index) => (
              <li
                key={index}
                className={
                  typeof item.isNew !== "undefined" && item.isNew === true
                    ? "new-feature"
                    : ""
                }
              >
                <div className={`u-integration__item-logo ${item.class}`}>
                  <item.component />
                </div>
                <h4 className="u-integration__item-name">{item.name}</h4>
                {typeof item.isNew !== "undefined" && item.isNew === true && (
                  <span className="new-badge">New</span>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="upcoming-integrations-check">
          {/* <Link to={`/integrations/upcoming`} >👉 Check the upcoming integrations</Link> */}
          <Link to={`/integrations/`}>
            {" "}
            See the full list of supported integrations.
          </Link>
        </div>
      </div>
    </section>
  )
}

/**
 * ADVANCED FEATURES
 */
const AdvancedFeaturesOverviewSection = () => {
  const features = [
    {
      title: "Website monitoring",
      description:
        "Fully customizable website monitoring set up with the custom request body, headers, and parameters.",
    },
    {
      title: "Public Status Page",
      description:
        "Display a public status page on your site or in-office mounted TV hosted on your company domain.",
    },
    {
      title: "Global coverage",
      description:
        "Get global latency metrics by running your monitors from 17 data center locations around the world.",
    },
    {
      title: "Alerting channels",
      description:
        "Use our generous data bundles and unlimited recipients/endpoints for SMS, email, webhooks, Slack, Telegram, Discord, Opsgenie, and PagerDuty alerting integrations.",
    },
    {
      title: "Team & users",
      description:
        "Collaborate with your team members by sharing your account. We've got you covered with complete access control.",
    },
    {
      title: "SSL expiry alerting",
      description:
        "We will notify you when your SSL certificate is about to expire 7, 10, 30 days, or a custom number of days in advance.",
    },
  ]

  return (
    <section className="u-advanced-features">
      <div className="container">
        <h2 className="u-advanced-features__title">Advanced Features</h2>
        <div className="u-advanced-features__items">
          <ul>
            {features.map((feature, index) => (
              <li key={index}>
                <div className="u-a-features__item-wrapper">
                  <div className="u-a-features__item--title">
                    {feature.title}
                  </div>
                  <div className="u-a-features__item--desc">
                    {feature.description}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*}
 */
const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Odown: Uptime Monitoring Tool & Status Pages"
        description="Simple website uptime monitoring tool to monitor your websites and APIs combined with public status pages. We'll also monitor your SSL certificates and notify you you through multiple alert channels if something happens."
        pathname={`/`}
        // meta={[
        // 	{
        // 		name : `twitter:image:src`,
        // 		content :  data?.site?.siteMetadata.siteUrl + '/general-image-og--odown.png'
        // 	},
        // 	{
        // 		name : `og:image`,
        // 		content : data?.site?.siteMetadata.siteUrl + '/general-image-og--odown.png'
        // 	},

        // ]}
        image={{
          src: "/general-image-og--home-page.jpg",
          width: 1600,
          height: 800,
        }}
      />
      {/* Main slider ( one slide ) */}
      <HeadingSection />
      {/* Hero section  */}
      <HeroSection data={data} />
      <TrustedByCompanies />
      {/* // ! DETAILS OF MAIN FEATURES  */}
      {/* website monitoring details feature  */}
      <ApiMonitoringMainFeatureSection data={data} />
      <StatusPagesMainFeatureSection data={data} />
      <IncidentManagementMainFeatureSection data={data} />
      <SSLMonitoringMainFeatureSection data={data} />
      <GlobalCoverageSection data={data} />
      {/* Integrations */}
      <IntegrationSection />
      {/* Advanced Features Overview */}
      <AdvancedFeaturesOverviewSection />
      {/* Price comparator with others alternative services */}
      <PricingComparator />
      {/* Monitor regions */}
      {/* <MonitorRegionsSection /> */}
      {/* Get started banner */}
      <GetStartedBanner />
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }

    HeroImageMonitoring: file(
      relativePath: { eq: "home-hero-api_monitoring.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    HeroImageStatusPage: file(
      relativePath: { eq: "home-hero-statuspage.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    HeroImageIncidentManagement: file(
      relativePath: { eq: "home-hero-incident_management.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    MainFeatureApiMonitoring: file(
      relativePath: { eq: "homepage--visualise-responsetime.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    MainFeatureIncidentManagement: file(
      relativePath: { eq: "home-product__incident-management.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    MainFeatureStatusPage: file(
      relativePath: { eq: "home-product__statuspage.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    MainFeatureSSLChecking: file(
      relativePath: {
        eq: "product__ssl-monitoring__option__trust-customer.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
export default Home
// PatternHeroBackground : file (relativePath : { eq : "Landing-page-bro.svg"} ){
// 	childImageSharp {
// 		fluid(maxWidth: 500, quality: 100) {
// 			...GatsbyImageSharpFluid
// 			...GatsbyImageSharpFluidLimitPresentationSize
// 		}
// 	}
// }
