const locations = [
  {
    id: "virginia",
    name: "virginia",
    location: "Virginia",
    aws_region_id: "us-east-1",
    countryCode: "US",
    countryName: "United State",
    chartColor: "#2196f3",
    isActive: true,
  },
  {
    id: "ohio",
    name: "ohio",
    location: "Ohio",
    aws_region_id: "us-east-2",
    countryCode: "US",
    countryName: "United State",
    chartColor: "#0057e7",
    isActive: true,
  },
  {
    id: "california",
    name: "california",
    location: "California",
    aws_region_id: "us-west-1",
    countryCode: "US",
    countryName: "United State",
    chartColor: "#a8e6cf",
    isActive: true,
  },
  {
    id: "oregon",
    name: "oregon",
    location: "Oregon",
    aws_region_id: "us-west-2",
    countryCode: "US",
    countryName: "United State",
    chartColor: "#ff77ff",
    isActive: true,
  },
  {
    id: "ireland",
    name: "ireland",
    location: "Ireland",
    aws_region_id: "eu-west-1",
    countryCode: "IE",
    countryName: "Ireland",
    chartColor: "#22ffaa",
    isActive: true,
  },
  {
    id: "london",
    name: "london",
    location: "London",
    aws_region_id: "eu-west-2",
    countryCode: "GB",
    countryName: "United Kingdom",
    chartColor: "#996666",
    isActive: true,
  },
  {
    id: "paris",
    name: "paris",
    location: "Paris",
    aws_region_id: "eu-west-3",
    countryCode: "FR",
    countryName: "France",
    chartColor: "#c8241b",
    isActive: true,
  },
  {
    id: "frankfurt",
    name: "frankfurt",
    location: "Frankfurt",
    aws_region_id: "eu-central-1",
    countryCode: "DE",
    countryName: "Germany",
    chartColor: "#ffc107",
    isActive: true,
  },
  {
    id: "singapore",
    name: "singapore",
    location: "Singapore",
    aws_region_id: "ap-southeast-1",
    countryCode: "SG",
    countryName: "Singapore",
    chartColor: "#673ab7",
    isActive: true,
  },
  {
    id: "sydney",
    name: "sydney",
    location: "Sydney",
    aws_region_id: "ap-southeast-2",
    countryCode: "AU",
    countryName: "Australia",
    chartColor: "#fbb40c",
    isActive: true,
  },
  {
    id: "tokyo",
    name: "tokyo",
    location: "Tokyo",
    aws_region_id: "ap-northeast-1",
    countryCode: "JP",
    countryName: "Japan",
    chartColor: "#81607f",
    isActive: true,
  },
  {
    id: "seoul",
    name: "seoul",
    location: "Seoul",
    aws_region_id: "ap-northeast-2",
    countryCode: "KR",
    countryName: "South Korea",
    chartColor: "#097f8c",
    isActive: true,
  },
  {
    id: "mumbai",
    name: "mumbai",
    location: "Mumbai",
    aws_region_id: "ap-south-1",
    countryCode: "IN",
    countryName: "India",
    chartColor: "#ff9932",
    isActive: true,
  },
  {
    id: "montreal",
    name: "montreal",
    location: "Montreal",
    aws_region_id: "ca-central-1",
    countryCode: "CA",
    countryName: "Canada",
    chartColor: "#d52c1e",
    isActive: true,
  },
  {
    id: "stockholm",
    name: "stockholm",
    location: "Stockholm",
    aws_region_id: "eu-north-1",
    countryCode: "SE",
    countryName: "Sweden",
    chartColor: "#ffdd00",
    isActive: true,
  },
  {
    id: "osaka",
    name: "osaka",
    location: "Osaka",
    aws_region_id: "ap-northeast-3",
    countryCode: "JP",
    countryName: "Japan",
    chartColor: "#00505c",
    isActive: true,
  },
  {
    id: "saopaulo",
    name: "saopaulo",
    location: "São Paulo",
    aws_region_id: "me-south-1",
    countryCode: "BR",
    countryName: "Brazil",
    chartColor: "#00225c",
    isActive: true,
  },
]

/**
 *
 */
export function getLocationList(LOCATION_STATUS = "active") {
  let response = null
  switch (LOCATION_STATUS) {
    case "active":
      response = locations.filter(x => x.isActive === true)
      break
    case "inactive":
      response = locations.filter(x => x.isActive === false)
      break
    default:
      response = locations
      break
  }

  return response
}
