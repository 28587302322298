import React from "react"
import ReactCountryFlag from "react-country-flag"
import { getLocationList } from "../../components/data-utils"

// Import css design
import "./global-coverage.css"

const GlobalCoverageSection = ({ data }) => {
  return (
    <section className="u-main-feature multiple-regions-coverage-wrapper">
      <div className="container">
        <div className="u-main-feature__wrapper one-tab">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">
              Worldwide website monitoring
            </span>
            <h2 className="u-main-feature_tc-title">Global coverage</h2>
            <p className="u-main-feature_tc-desc">
              Get a clear picture of how your services perform for all your
              users worldwide. Run your monitor from 17 different data center
              locations spread over the Americas, EMEA, and the Asia Pacific.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              {/* <Img fadeIn={false} loading="eager" fluid={data.FeatureGlobalCoverage.childImageSharp.fluid} alt="Website monitoring"/> */}
              <div className="multiple-regions-coverage">
                <ul>
                  {getLocationList("active").map((region, index) => (
                    <li key={index}>
                      <span className="region-flag">
                        <ReactCountryFlag
                          countryCode={region.countryCode}
                          svg
                          title={region.location}
                          className="c-names"
                          alt={`${region.location} - ${region.countryName}`}
                        />
                      </span>
                      <div className="region-data">
                        <span className="region--name">{region.location}</span>
                        <span className="region--country">
                          {region.countryName}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GlobalCoverageSection
