import React, { useState, useEffect } from "react"

import PingdomTinyLogo from "./../../assets/icons/svg/pingdom-tiny-logo.svg"
import UptimerobotTinyLogo from "./../../assets/icons/svg/uptime_robot-tiny-logo.svg"
import PagerDutyTinyLogo from "./../../assets/icons/svg/pagerduty-tiny-logo.svg"
import StatuspageTinyLogo from "./../../assets/icons/svg/statuspage-io-tiny-logo.svg"
import StatusCakeTinyLogo from "./../../assets/icons/svg/statuscake-tiny-logo.svg"

const PricingComparator = ({ data }) => {
  const [pingdomPrice] = useState({ price: 50, active: true })
  const [uptimerobotPrice] = useState({ price: 15, active: true })
  const [pagerDutyPrice] = useState({ price: 241, active: true })
  const [statusPagePrice] = useState({ price: 99, active: true })
  const [statuscakePrice] = useState({ price: 23, active: true })
  const [totalCompetitorPrice, settotalCompetitorPrice] = useState(0)
  const [odownPrice] = useState({ price: 29, active: true })

  useEffect(() => {
    let total = 0
    if (
      data?.selectedUptimeTool === "uptimerobot" &&
      uptimerobotPrice?.active === true
    )
      total += uptimerobotPrice?.price
    else if (
      data?.selectedUptimeTool === "statuscake" &&
      statuscakePrice?.active === true
    )
      total += statuscakePrice?.price
    else if (pingdomPrice?.active === true) total += pingdomPrice?.price

    if (pagerDutyPrice?.active === true) total += pagerDutyPrice?.price
    if (statusPagePrice?.active === true) total += statusPagePrice?.price

    settotalCompetitorPrice(total)
  }, [])

  return (
    <section className="alternatives-services-cost-comparator-wrapper">
      <div className="container">
        <h2>Save big with Odown</h2>
        <p>
          Odown replaces a bunch of apps. See how our Pro plan price compares
          with{" "}
          {data?.selectedUptimeTool === "uptimerobot"
            ? "Uptime Robot"
            : data?.selectedUptimeTool === "statuscake"
            ? "StatusCake"
            : "Pingdom"}
          , PagerDuty, and Statuspage.io.
        </p>
        <p className="note">Assuming 50 monitors and 6 users</p>
        <div className="comparison-cards ">
          <div className="comparison-card competitors">
            <div className="service-cards">
              {data?.selectedUptimeTool === "uptimerobot"
                ? uptimerobotPrice?.active === true && (
                    <div className="service-card uptimerobot-service">
                      <div className="service-card__flex">
                        <div className="service-icon">
                          <UptimerobotTinyLogo />
                        </div>
                        <div className="service-details">
                          <div className="service-name">
                            <p>Uptime Robot</p>
                            <p className="service-propose">
                              Website monitoring
                            </p>
                          </div>
                          <div className="service-price">
                            <sup className="price-currency">$</sup>
                            <span className="price-big-bank">
                              {uptimerobotPrice?.price}
                            </span>
                            <span className="price-period">/mo</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                : data?.selectedUptimeTool === "statuscake"
                ? statuscakePrice?.active === true && (
                    <div className="service-card statuscake-service">
                      <div className="service-card__flex">
                        <div className="service-icon">
                          <StatusCakeTinyLogo />
                        </div>
                        <div className="service-details">
                          <div className="service-name">
                            <p>StatusCake</p>
                            <p className="service-propose">
                              Website monitoring
                            </p>
                          </div>
                          <div className="service-price">
                            <sup className="price-currency">$</sup>
                            <span className="price-big-bank">
                              {statuscakePrice?.price}
                            </span>
                            <span className="price-period">/mo</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                : pingdomPrice?.active === true && (
                    <div className="service-card pingdom-service">
                      <div className="service-card__flex">
                        <div className="service-icon">
                          <PingdomTinyLogo />
                        </div>
                        <div className="service-details">
                          <div className="service-name">
                            <p>Pingdom</p>
                            <p className="service-propose">
                              Website monitoring
                            </p>
                          </div>
                          <div className="service-price">
                            <sup className="price-currency">$</sup>
                            <span className="price-big-bank">
                              {pingdomPrice?.price}
                            </span>
                            <span className="price-period">/mo</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              {pagerDutyPrice?.active === true && (
                <div className="service-card pagerduty-service">
                  <div className="service-card__flex">
                    <div className="service-icon">
                      <PagerDutyTinyLogo />
                    </div>
                    <div className="service-details">
                      <div className="service-name">
                        <p>PagerDuty</p>
                        <p className="service-propose">Incident Management</p>
                      </div>
                      <div className="service-price">
                        <sup className="price-currency">$</sup>
                        <span className="price-big-bank">
                          {pagerDutyPrice?.price}
                        </span>
                        <span className="price-period">/mo</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {
                //(typeof data?.selectedUptimeTool==='undefined' || data?.selectedUptimeTool !== 'statuscake') &&
                statusPagePrice?.active === true && (
                  <div className="service-card statuspage-service">
                    <div className="service-card__flex">
                      <div className="service-icon">
                        <StatuspageTinyLogo />
                      </div>
                      <div className="service-details">
                        <div className="service-name">
                          <p>Statuspage.io</p>
                          <p className="service-propose">Branded status page</p>
                        </div>
                        <div className="service-price">
                          <sup className="price-currency">$</sup>
                          <span className="price-big-bank">
                            {statusPagePrice?.price}
                          </span>
                          <span className="price-period">/mo</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
            <div className="comparison-summary">
              <div className="comparison-summary__price">
                <sup className="price-currency">$</sup>
                <span className="price-big-bank">{totalCompetitorPrice}</span>
                <span className="price-period">/mo</span>
              </div>
              <div className="comparison-summary__comment">
                $<span>{totalCompetitorPrice}</span>/month for 6 team members
                and <span>50 monitors</span>.
              </div>
            </div>
          </div>
          <div className="comparison-vs">
            <span>vs</span>
          </div>
          <div className="comparison-card">
            <div className="service-cards">
              <div className="service-card self-service">
                <div className="service-card__flex">
                  <div className="service-icon">
                    <img src="/odown-favicon.png" alt="Odown" />
                  </div>
                  <div className="service-details">
                    <div className="service-name">
                      <p>Odown</p>
                    </div>
                  </div>
                </div>
                <ul className="service-features">
                  <li>Website monitoring built-in</li>
                  <li>Incident management built-in</li>
                  <li>You + 9 team users</li>
                  <li>
                    Branded status page on your own domain{" "}
                    <span className="d-none d-sm-inline-block">
                      status.yourdomain.com
                    </span>
                  </li>
                  <li>Simple and beautiful interface</li>
                </ul>
              </div>
            </div>
            <div className="comparison-summary">
              <div className="comparison-summary__price">
                <sup className="price-currency">$</sup>
                <span className="price-big-bank">{odownPrice?.price}</span>
                <span className="price-period">/mo</span>
              </div>
              <div className="comparison-summary__comment">
                $<span>{odownPrice?.price}</span>/month for 6 team members and{" "}
                <span>50 monitors</span>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PricingComparator
